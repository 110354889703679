import ProtectedRoute from "./ProtectedRoute";
import UserHome from "../components/public_user_components/UserHomeComponent";
import BulkBuyerHome from "../components/vendor_components/BulkBuyerHomeComponent";
import AddItem from "../components/admin_components/addMasterItem/AddItemComponent";
import AddVendorInventory from "../components/admin_components/addVendorInventory/AddVendorInventory";
import Cart from "../components/vendor_components/Cart";
import Orders from "../components/vendor_components/Orders";
import WorkerOrderPage from "../components/worker_components/WorkerOrderPage";
import WorkerUpdateItemPrice from "../components/worker_components/WorkerUpdateItemPrice";
import WorkerLoadingPage from "../components/worker_components/WorkerLoadingPage";
import PurchaseList from "../components/worker_components/PurchaseList";
import VendorInventory from "../components/temporaryComponents/VendorInventory";
import PastOrder from "../components/vendor_components/PastOrder";
import VendorProfile from "../components/vendor_components/VendorProfile";
import WorkerProfile from "../components/worker_components/WorkerProfile";
import RetailBuyerHome from "../components/retail_Buyer_components/RetailBuyerHome";
import RetailCart from "../components/retail_Buyer_components/Cart";
import RetailOrders from "../components/retail_Buyer_components/Orders";
import RetailPastOrder from "../components/retail_Buyer_components/PastOrder";
import DriverProfile from "../components/driver_components/DriverProfile";
import DriverOrderPage from "../components/driver_components/DriverOrderPage";
import DriverOrder from "../components/driver_components/DriverOrder";
import DriverRedirect from "../components/driver_components/DriverRedirect";
import UserManagement from "../components/admin_components/UserManagement";
import AdminProfile from "../components/admin_components/AdminProfile";
import OrderManagement from "../components/admin_components/OrderManagement";
import Statistics from "../components/admin_components/Statistics";
import CommingSoon from "../components/temporaryComponents/NotFound/CommingSoon";
import UnderConstruction from "../components/temporaryComponents/UnderConstruction";
import AdminUpdateItemPrice from "../components/admin_components/AdminUpdateItemPrice";
import DriverOrderReceipt from "../components/driver_components/DriverOrderReceipt";
import WorkerPlaceOrder from "../components/worker_components/WorkerPlaceOrder";
import WorkerUpdateOrder from "../components/worker_components/WorkerUpdateOrder";
import InventoryManagement from "../components/admin_components/InventoryManagement";
import RegisterCrate from "../components/admin_components/RegisterCrate";
import RegisterMasterCrate from "../components/admin_components/RegisterMasterCrate";
import WorkerTagCrate from "../components/worker_components/WorkerTagCrate";
import CoordinationManagement from "../components/worker_components/CoordinationManagement";
import WorkerSendCrate from "../components/worker_components/WorkerSendCrate";
import WorkerReceiveCrate from "../components/worker_components/WorkerReceiveCrate";
import OrderCrateDetailsView from "../components/worker_components/WorkerOrderCrateView";

const routeComponents = {
    userHome: () => {
        return (
            <ProtectedRoute>
                <UserHome />
            </ProtectedRoute>
        );
    },
    retailBuyerHome: () => {
        return (
            <ProtectedRoute>
                <RetailBuyerHome />
            </ProtectedRoute>
        );
    },
    comingSoon: () => {
        return (
            <ProtectedRoute>
                <CommingSoon />
            </ProtectedRoute>
        );
    },
    retailBuyerCart: () => {
        return (
            <ProtectedRoute>
                <RetailCart />
            </ProtectedRoute>
        );
    },
    retailBuyerOrders: () => {
        return (
            <ProtectedRoute>
                <RetailOrders />
            </ProtectedRoute>
        );
    },
    retailBuyerOrder: () => {
        return (
            <ProtectedRoute>
                <RetailPastOrder />
            </ProtectedRoute>
        );
    },
    bulkBuyerHome: () => {
        return (
            <ProtectedRoute>
                <BulkBuyerHome />
            </ProtectedRoute>
        );
    },
    bulkBuyerCart: () => {
        return (
            <ProtectedRoute>
                <Cart />
            </ProtectedRoute>
        );
    },
    bulkBuyerOrders: () => {
        return (
            <ProtectedRoute>
                <Orders />
            </ProtectedRoute>
        );
    },
    bulkBuyerOrder: () => {
        return (
            <ProtectedRoute>
                <PastOrder />
            </ProtectedRoute>
        );
    },
    adminAddItem: () => {
        return (
            <ProtectedRoute>
                <AddItem />
            </ProtectedRoute>
        );
    },
    adminInventoryManagement: () => {
        return (
            <ProtectedRoute>
                <InventoryManagement />
            </ProtectedRoute>
        );
    },
    adminAddInventory: () => {
        return (
            <ProtectedRoute>
                <AddVendorInventory />
            </ProtectedRoute>
        );
    },
    adminUsermanagement: () => {
        return (
            <ProtectedRoute>
                <UserManagement />
            </ProtectedRoute>
        );
    },
    adminOrdermanagement: () => {
        return (
            <ProtectedRoute>
                <OrderManagement />
            </ProtectedRoute>
        );
    },
    adminStatistics: () => {
        return (
            <ProtectedRoute>
                <Statistics />
            </ProtectedRoute>
        );
    },
    adminPriceSync: () => {
        return (
            <ProtectedRoute>
                <AdminUpdateItemPrice />
            </ProtectedRoute>
        );
    },
    adminProfile: () => {
        return (
            <ProtectedRoute>
                <AdminProfile />
            </ProtectedRoute>
        );
    },
    adminCrateRegistration: () => {
        return (
            <ProtectedRoute>
                <RegisterCrate />
            </ProtectedRoute>
        );
    },
    adminMasterCrateRegistration: () => {
        return (
            <ProtectedRoute>
                <RegisterMasterCrate />
            </ProtectedRoute>
        );
    },
    workerUpdatePrice: () => {
        return (
            <ProtectedRoute>
                <WorkerUpdateItemPrice />
            </ProtectedRoute>
        );
    },
    workerCoordinationManagement: () => {
        return (
            <ProtectedRoute>
                <CoordinationManagement />
            </ProtectedRoute>
        );
    },
    workerReceiveCrate: () => {
        return (
            <ProtectedRoute>
                <WorkerReceiveCrate />
            </ProtectedRoute>
        );
    },
    workerSendCrate: () => {
        return (
            <ProtectedRoute>
                <WorkerSendCrate />
            </ProtectedRoute>
        );
    },
    workerOrders: () => {
        return (
            <ProtectedRoute>
                <WorkerOrderPage />
            </ProtectedRoute>
        );
    },
    workerLoading: () => {
        return (
            <ProtectedRoute>
                <WorkerLoadingPage />
            </ProtectedRoute>
        );
    },
    workerPurchaseList: () => {
        return (
            <ProtectedRoute>
                <PurchaseList />
            </ProtectedRoute>
        );
    },
    workerPlaceOrder: () => {
        return (
            <ProtectedRoute>
                <WorkerPlaceOrder />
            </ProtectedRoute>
        );
    },
    workerUpdateOrder: () => {
        return (
            <ProtectedRoute>
                <WorkerUpdateOrder />
            </ProtectedRoute>
        );
    },
    workerTagCrate: () => {
        return (
            <ProtectedRoute>
                <WorkerTagCrate />
            </ProtectedRoute>
        );
    },
    workerViewCrate: () => {
        return (
            <ProtectedRoute>
                <OrderCrateDetailsView />
            </ProtectedRoute>
        );
    },
    vendorInventory: () => {
        return (
            <ProtectedRoute>
                <UnderConstruction />
            </ProtectedRoute>
        );
    },
    vendorProfile: () => {
        return (
            <ProtectedRoute>
                <VendorProfile />
            </ProtectedRoute>
        );
    },
    workerProfile: () => {
        return (
            <ProtectedRoute>
                <WorkerProfile />
            </ProtectedRoute>
        );
    },
    driverProfile: () => {
        return (
            <ProtectedRoute>
                <DriverProfile />
            </ProtectedRoute>
        );
    },
    driverOrders: () => {
        return (
            <ProtectedRoute>
                <DriverOrderPage />
            </ProtectedRoute>
        );
    },
    driverOrder: () => {
        return (
            <ProtectedRoute>
                <DriverOrder />
            </ProtectedRoute>
        );
    },
    driverOrderReceipt: () => {
        return (
            <ProtectedRoute>
                <DriverOrderReceipt />
            </ProtectedRoute>
        );
    },
    driverRedirect: () => {
        return (
            <ProtectedRoute>
                <DriverRedirect />
            </ProtectedRoute>
        );
    },
};

export default routeComponents;
