import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import state from "./state";
import reducers from "./reducers";
import { customToast } from "../../../components/public_components/Toast/CustomToast";
import { crateProvider } from "../../../provider/firebase";
import { Crate } from "../../../utils/crateUtils";

// Async action for saving user data
const asyncThunks = {
    addMasterCrate: createAsyncThunk(
        "crate/addMasterCrate",
        async (crateData: any) => {
            try {
                await crateProvider.addMasterCrate(crateData);
                customToast("Crate Type Added", "success");
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    subscribeCrateList: createAsyncThunk(
        "crate/subscribeCrateList",
        async (_, { dispatch }) => {
            try {
                await crateProvider.subscribeCrateList(dispatch);
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    addCrate: createAsyncThunk(
        "crate/addCrate",
        async (payload: any, { getState }) => {
            try {
                const state: any = getState();
                const currentUser = state.user?.currentUser;

                const finalPayload: Crate = {
                    master_crate_doc_reference: null,
                    id: payload?.crateType?.id,
                    created_at: new Date(),
                    crate_QR_Code: payload?.crateQRCode,
                    crate_number: payload?.crateNumber,
                    crate_prefix: payload?.cratePrefix,
                    image: payload?.crateType?.image,
                    max_weight_capacity:
                        payload?.crateType?.crate_weight_capacity,
                    crate_size: payload?.crateType?.size,
                    crate_color: payload?.crateType?.color,
                    crate_last_user: {
                        user_ref: null,
                        user_data: currentUser,
                    },
                    current_item_in_crate: {
                        item_ref: null,
                        item_data: null,
                        quantity: 0,
                    },
                    is_crate_has_item: false,
                    current_crate_order: {
                        ref: null,
                        id: "",
                        to: "",
                    },
                    is_out: false,
                };
                await crateProvider.addCrate(finalPayload);
                customToast("Crate Added", "success");
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    tagOrderCrate: createAsyncThunk(
        "crate/tagOrderCrate",
        async (payload: any) => {
            try {
                const result = await crateProvider.tagOrderCrate(payload);
                return result;
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    tagReceiveCrate: createAsyncThunk(
        "crate/tagReceiveCrate",
        async (payload: any) => {
            try {
                const result = await crateProvider.tagCrate({
                    ...payload,
                    mode: "receive",
                });
                return result;
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    tagSendCrate: createAsyncThunk(
        "crate/tagSendCrate",
        async (payload: any) => {
            try {
                const result = await crateProvider.tagCrate({
                    ...payload,
                    mode: "send",
                });
                return result;
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    fetchOrderCrate: createAsyncThunk(
        "crate/fetchOrderCrate",
        async (payload: any) => {
            try {
                const result = await crateProvider.fetchOrderCrate(payload);
                return result;
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
    updateCrateDeliveryState: createAsyncThunk(
        "crate/updateCrateDeliveryState",
        async (payload: any) => {
            try {
                await crateProvider.updateCrateDeliveryState(payload);
                return { isError: false, message: "success" };
            } catch (error) {
                customToast("Update Failed!", "error");
                return { isError: true, message: error };
            }
        }
    ),
    subscribeToCrateCollection: createAsyncThunk(
        "crate/subscribeToCrateCollection",
        async (_arg, { dispatch }) => {
            try {
                const result = await crateProvider.subscribeToCrateCollection(
                    dispatch
                );
                return result;
            } catch (error) {
                customToast("Error Occured", "error");
                return { isError: true, message: error };
            }
        }
    ),
};

const crateSlice = createSlice({
    // Name of the slice
    name: "crate",
    // State of the slice
    initialState: state,
    // Reducers of the slice
    reducers: reducers,
});

export const actions = { ...crateSlice.actions, ...asyncThunks };
export default crateSlice.reducer;
