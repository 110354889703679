import {
    arrayUnion,
    deleteField,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    Timestamp,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import { db, functions, orderCollection, userCollection } from "..";
import { httpsCallable } from "firebase/functions";
import { OrderStatus, isValidOrderTime } from "../../../utils/orderUtils";
import { OrderCartItem } from "order";
import orderSlice from "../../../store/slices/order";
import _ from "lodash";
import { customToast } from "../../../components/public_components/Toast/CustomToast";

let selectedOrderSnapshotListennerUnsubscribe: any = null;
let ordersSnapshot: any = null;

const order = {
    // Use this only with Admin SDK
    async addBulkOrder(payload: {
        cart: any;
        user: any;
        deliveryLocation: any;
        deliveryDate: Date;
        deliveryDateText: string;
    }) {
        let result: any;
        const isOrderingEnabled = await isValidOrderTime();
        if (isOrderingEnabled.isBlocked) {
            result = {
                isError: true,
                message: {
                    message: `Ordering Disabled Between ${isOrderingEnabled.startTime} to ${isOrderingEnabled.endTime}`,
                },
            };
        } else {
            const createBulkOrder = httpsCallable(functions, "createBulkOrder");
            result = (await createBulkOrder(payload))?.data;
        }

        return result;
    },
    // Vendor/BulkBuyer buyer only
    async fetchUserOnGoingBulkOrders(payload: any) {
        const userRef = doc(db, userCollection.path, payload.id);
        const pendingOrderQuery = query(
            orderCollection,
            where("belongs_to", "==", userRef),
            where("order_status", "in", [
                OrderStatus.Pending,
                OrderStatus.Processing,
                OrderStatus.Ready,
                OrderStatus.OutForDelivery,
            ])
        );
        const querySnapshot = await getDocs(pendingOrderQuery);
        const orderArray = [];
        for (const doc of querySnapshot.docs) {
            const docData = doc.data();
            orderArray.push(docData);
        }
        return orderArray;
    },
    async fetchUserShippedBulkOrders(payload: any) {
        const userRef = doc(db, userCollection.path, payload.id);
        const pendingOrderQuery = query(
            orderCollection,
            where("belongs_to", "==", userRef),
            where("order_status", "in", [
                OrderStatus.shipped,
                OrderStatus.OutForDelivery,
            ])
        );
        const querySnapshot = await getDocs(pendingOrderQuery);
        const orderArray = [];
        for (const doc of querySnapshot.docs) {
            const docData = doc.data();
            orderArray.push(docData);
        }
        return orderArray;
    },
    async fetchUserDeliveredBulkOrders(payload: any) {
        const userRef = doc(db, userCollection.path, payload.id);
        const pendingOrderQuery = query(
            orderCollection,
            where("belongs_to", "==", userRef),
            where("order_status", "==", OrderStatus.Delivered)
        );
        const querySnapshot = await getDocs(pendingOrderQuery);
        const orderArray = [];
        for (const doc of querySnapshot.docs) {
            const docData = doc.data();
            orderArray.push(docData);
        }
        return orderArray;
    },
    // Use for only Worker User
    async syncWorkerOrders(payload: any, dispatch: any) {
        let orderQuery = null;

        // Convert the delivery_date to a Firestore Timestamp
        const startOfDay = new Date(payload.deliveryDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(payload.deliveryDate);
        endOfDay.setHours(23, 59, 59, 999);

        const startTimestamp = Timestamp.fromDate(startOfDay);
        const endTimestamp = Timestamp.fromDate(endOfDay);

        if (payload?.tab === "ongoing") {
            orderQuery = query(
                orderCollection,
                where("order_status", "in", [
                    OrderStatus.Pending,
                    OrderStatus.Processing,
                ]),
                where("delivery_date", ">=", startTimestamp),
                where("delivery_date", "<=", endTimestamp)
            );
        } else if (payload?.tab === "ready") {
            orderQuery = query(
                orderCollection,
                where("order_status", "==", OrderStatus.Ready),
                where("delivery_date", ">=", startTimestamp),
                where("delivery_date", "<=", endTimestamp)
            );
        } else if (payload?.tab === "shipped") {
            orderQuery = query(
                orderCollection,
                where("order_status", "in", [
                    OrderStatus.shipped,
                    OrderStatus.OutForDelivery,
                ]),
                where("delivery_date", ">=", startTimestamp),
                where("delivery_date", "<=", endTimestamp)
            );
        }
        await this.unsubscribeOrders(dispatch);
        if (orderQuery == null) {
            return;
        }
        ordersSnapshot = onSnapshot(orderQuery, async (documents: any) => {
            const ordersArray: any = [];
            for (const document of documents.docs) {
                ordersArray.push(document.data());
            }
            dispatch(orderSlice.setOrders(ordersArray));
        });
    },
    async updateWorkerBulkOrder(payload: { cart: any; order: any; user: any }) {
        try {
            let result: any;
            const updateBulkOrder = httpsCallable(functions, "updateBulkOrder");
            const t = await updateBulkOrder(payload);
            result = t?.data;
            return result;
        } catch (error) {
            console.error(error);
        }
    },

    // Use for Driver user
    async syncDriverOrders(orderType: any, dispatch: any, getState: any) {
        const { user } = getState();
        const driverDoc = doc(userCollection, user?.currentUser?.id);
        let orderQuery = null;
        if (orderType === "ongoing") {
            orderQuery = query(
                orderCollection,
                where("order_status", "in", [OrderStatus.OutForDelivery]),
                where("delivery_driver_ref", "==", driverDoc)
            );
        } else if (orderType === "shipped") {
            orderQuery = query(
                orderCollection,
                where("order_status", "in", [OrderStatus.Delivered]),
                where("delivery_driver_ref", "==", driverDoc)
            );
        }
        await this.unsubscribeOrders(dispatch);
        if (orderQuery == null) {
            return;
        }
        ordersSnapshot = onSnapshot(orderQuery, async (documents: any) => {
            const ordersArray: any = [];
            for (const document of documents.docs) {
                ordersArray.push(document.data());
            }
            const chars: any = _.orderBy(
                ordersArray,
                ["delivery_order_num"],
                ["desc"]
            );
            if (!chars) {
                return;
            }
            await dispatch(orderSlice.setOrders(chars));
        });
    },
    async unsubscribeOrders(dispatch: any) {
        if (ordersSnapshot) {
            await ordersSnapshot();
        }
        await dispatch(orderSlice.clearOrders());
    },
    async updateOrderItemSourceCheckbox(payload: {
        order: any;
        cartItemId: any;
        checkboxValue: any;
        user: any;
    }) {
        let updatedCartItemArray: any[] = [];
        let orderStatusArray: boolean[] = [];
        let orderStatus: OrderStatus = OrderStatus.Processing;

        for (const item of payload.order.items) {
            if (item.item.id === payload.cartItemId) {
                const updatedNewCartItem: OrderCartItem = {
                    ...item,
                    is_ready: payload.checkboxValue,
                };
                updatedCartItemArray.push(updatedNewCartItem);
                if (payload.checkboxValue == true) {
                    orderStatusArray.push(true);
                } else {
                    orderStatusArray.push(false);
                }
            } else {
                orderStatusArray.push(item.is_ready);
                updatedCartItemArray.push(item);
            }
        }
        if (!orderStatusArray.includes(false)) {
            orderStatus = OrderStatus.Ready;
            const orderDocRef = doc(orderCollection, payload.order.id);
            await updateDoc(orderDocRef, {
                items: updatedCartItemArray,
                order_status: orderStatus,
                order_delivery_log: arrayUnion({
                    created_by_user_id: payload.user.id,
                    status_type: OrderStatus.Ready,
                    text: "Bulk Order is Ready to Ship",
                    timestamp: new Date(),
                }),
            });
        } else {
            const orderDocRef = doc(orderCollection, payload.order.id);
            await updateDoc(orderDocRef, {
                items: updatedCartItemArray,
                order_status: orderStatus,
            });
        }
    },
    async updateOrderAllItemsSourceCheckbox(payload: {
        order: any;
        user: any;
    }) {
        let updatedCartItemArray: any[] = [];
        let orderStatus: OrderStatus = OrderStatus.Processing;

        for (const item of payload.order.items) {
            const updatedNewCartItem: OrderCartItem = {
                ...item,
                is_ready: true,
            };
            updatedCartItemArray.push(updatedNewCartItem);
        }

        orderStatus = OrderStatus.Ready;
        const orderDocRef = doc(orderCollection, payload.order.id);
        await updateDoc(orderDocRef, {
            items: updatedCartItemArray,
            order_status: orderStatus,
            order_delivery_log: arrayUnion({
                created_by_user_id: payload.user.id,
                status_type: OrderStatus.Ready,
                text: "Bulk Order is Ready to Ship",
                timestamp: new Date(),
            }),
        });
    },
    async syncSelectedOrder(orderId: any, dispatch: any) {
        const orderDocumentRef = doc(orderCollection, orderId);
        await this.unsubscribeSelectedOrder(dispatch);
        selectedOrderSnapshotListennerUnsubscribe = onSnapshot(
            orderDocumentRef,
            async (docData: any) => {
                await dispatch(
                    orderSlice.setWorkerSelectedOrderObject(docData.data())
                );
            }
        );
    },
    async unsubscribeSelectedOrder(dispatch: any) {
        if (selectedOrderSnapshotListennerUnsubscribe) {
            selectedOrderSnapshotListennerUnsubscribe();
        }
        await dispatch(orderSlice.clearWorkerSelectedOrderObject());
    },
    async fetchLoadingOrder(payload: any) {
        const fetchLoadingOrder = httpsCallable(functions, "fetchLoadingOrder");
        const result: any = await fetchLoadingOrder(payload);
        return result;
    },
    async setLoadedOrders(payload: any) {
        const setLoadedOrders = httpsCallable(functions, "setLoadedOrders");
        const result: any = await setLoadedOrders(payload);
        return result;
    },
    async fetchPurchaseItemsList(payload: any) {
        const startOfDay = new Date(payload.deliveryDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(payload.deliveryDate);
        endOfDay.setHours(23, 59, 59, 999);

        const startTimestamp = startOfDay.valueOf();
        const endTimestamp = endOfDay.valueOf();
        const finalPayload = {
            ...payload,
            startTimestamp,
            endTimestamp,
        };
        const fetchWorkerTotalPurchaseList = httpsCallable(
            functions,
            "fetchWorkerTotalPurchaseList"
        );
        const result: any = await fetchWorkerTotalPurchaseList(finalPayload);
        return result.data;
    },
    async fetchOrder(payload: any) {
        const docRef = doc(orderCollection, payload?.orderId);
        const data = await getDoc(docRef);
        const userDoc = await getDoc(data.data()?.belongs_to);
        return { ...data.data(), userData: userDoc?.data() };
    },
    async clearVehicle(payload: any) {
        try {
            const batch = writeBatch(db);
            const driverDocRef = doc(userCollection, payload);
            const q = query(
                orderCollection,
                where("delivery_driver_ref", "==", driverDocRef),
                where("order_status", "==", OrderStatus.OutForDelivery)
            );
            const docs = await getDocs(q);
            for (const order of docs.docs) {
                batch.update(order.ref, {
                    delivery_driver_ref: deleteField(),
                    delivery_order_num: deleteField(),
                    is_loaded: false,
                    no_of_bags: deleteField(),
                    order_status: OrderStatus.Ready,
                });
            }
            batch.update(driverDocRef, { is_ready: true });
            await batch.commit();
        } catch (error) {
            throw new Error("Error occured!");
        }
    },
    async completeOrder(payload: any, getState: any) {
        try {
            const { user } = getState();
            const orderDoc = doc(orderCollection, payload?.orderId);
            const updateObject = {
                text: "Order is Delivered",
                status_type: OrderStatus.Delivered,
                created_by_user_id: user?.currentUser?.id,
                timestamp: new Date(),
            };
            if (payload?.isOrderPriceOverried) {
                const result = await this.updateNewPricesInOrder(payload);
                await updateDoc(orderDoc, {
                    items: result?.newItemArray,
                    total_price: result?.newTotalPrice,
                    order_delivery_log: arrayUnion(updateObject),
                    order_status: OrderStatus.Delivered,
                });
            } else {
                await updateDoc(orderDoc, {
                    order_delivery_log: arrayUnion(updateObject),
                    order_status: OrderStatus.Delivered,
                });
            }
        } catch (error) {
            throw new Error("Error Occured");
        }
    },
    // Price overried at delivery time
    // Todo: Move to cloud functions
    async updateNewPricesInOrder(payload: any) {
        const orderDocRef = doc(orderCollection, payload?.orderId);
        const orderDoc = await getDoc(orderDocRef);
        const newItemArray = [];
        let newTotalPrice = 0;
        for (const item of orderDoc.data()?.items) {
            const itemDoc: any = await getDoc(item?.item);
            const newItemBulkPrice = itemDoc?.data()?.unit_price?.bulk_price;
            const itemPrice = Math.round(newItemBulkPrice * item?.quantity);
            const updatedItem = {
                ...item,
                unit_price: newItemBulkPrice,
                total_item_price: itemPrice,
            };
            newItemArray.push(updatedItem);
            newTotalPrice += itemPrice;
        }
        return { newItemArray, newTotalPrice };
    },
    async updateAdminOrderDetails(orderDataPayload: any, getState: any) {
        try {
            const { user } = getState();
            if (user?.currentUser?.role !== "admin") {
                customToast("No permission", "error");
                return { isError: false, message: "No permission" };
            }
            let firebaseUpdateObject = {};
            const { orderData, updatedData } = orderDataPayload;

            if (orderData?.order_status !== updatedData?.orderStatus) {
                firebaseUpdateObject = {
                    ...firebaseUpdateObject,
                    order_status: updatedData?.orderStatus,
                };
            }
            if (orderData?.payment_status !== updatedData?.paymentStatus) {
                firebaseUpdateObject = {
                    ...firebaseUpdateObject,
                    payment_status: updatedData?.paymentStatus,
                };
            }
            if (orderData?.paid_amount !== updatedData?.paidAmount) {
                firebaseUpdateObject = {
                    ...firebaseUpdateObject,
                    paid_amount: updatedData?.paidAmount,
                };
            }
            const orderDocRef = doc(orderCollection, orderData?.id);
            const result = await updateDoc(orderDocRef, firebaseUpdateObject);
            return result;
        } catch (error) {
            throw new Error("Error Occured");
        }
    },
    async updateAdminAdvanceUpdateOrderDetails(orderData: any, getState: any) {
        try {
            const { user } = getState();
            console.log("ResultA: ", orderData);
            const orderDocRef = doc(orderCollection, orderData?.orderId);
            const result = await updateDoc(
                orderDocRef,
                orderData?.orderJSONObject
            );
            return result;
        } catch (error) {
            throw new Error("Error Occured");
        }
    },
    async fetchOrderInvoice(orderId: string) {
        try {
            const getInvoiceDatagenerateOrderInvoice = httpsCallable(
                functions,
                "generateOrderInvoice"
            );
            await getInvoiceDatagenerateOrderInvoice(orderId);
            customToast("Invoice Available to Download", "success");
        } catch (error) {
            throw new Error("Error Occured");
        }
    },
};

export default order;
